import React from 'react'
import logo from '../images/logo.svg'

export default function header() {
  return (
    <div className="header">
      <img className='logo' src={logo} alt="logo" />
    </div>
  )
}
