import React, { useState } from 'react'
import PrimaryPopup from './PrimaryPopup'

export default function Hero() {
  const [active, setActive] = useState(false)
  function isClosed(e) {
    setActive(false)
  }
  
  return (
    <section className='hero'>
        <div className='header-wrapper'>
            <h1 className="hero-header">
              We Deliver You <span>Quote-ready</span> Leads <br />
              - You <span>Dont</span> Lift a Finger!
            </h1>
            <h3 className="secondary-header">We get you <span>5</span> new Quote-Ready leads Or your <span>Money Back</span>  guaranteed</h3>
        </div>
        <button onClick={(e) => { setActive(true) }} className='button' datatype-button='primary'> Get in touch</button>
        <PrimaryPopup isOpen={active} isClosed={isClosed} isCanceled />
    </section>
  )
}
