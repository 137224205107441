import React, { useEffect, useRef, useState } from 'react'
import emailjs from 'emailjs-com'
export default function PrimaryPopup(props) {
  const [formData, setFormData] = useState({
    fullName:'',
    emailAddress:'',
    phoneNumber:'',
    message:'',
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const modalRef = useRef(null)
  useEffect(() => {
    if (props.isOpen) modalRef.current.showModal();
  }, [props.isOpen])
  
  const closeModal = (e) => {
    modalRef.current.close();
    props.isClosed(true, 'hello')
  }
  const cancelModal = (e) => {
    modalRef.current.close();
    props.isClosed()
  }
  const handleClick = (e) => {
    if (e.target === modalRef.current) {
      modalRef.current.close();
      cancelModal()
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const serviceId = 'service_ac850he'
    const templateId = 'template_tz7phyj'
    const userId = 'kF2wbdLwaYfKSZwy4'
    const templateParams = {
      from_name: formData.fullName,
      to_name: 'RevampShore',
      message: formData.message,
      phoneNumber: formData.phoneNumber,
      emailAddress: formData.emailAddress,
    }
    
    emailjs.send(serviceId,templateId,templateParams,userId)
    .then((response) => {
      console.log(response);
      window.location.reload()

    })
    .catch((error) => {
      console.log(error);
     })
    console.log(formData);
    closeModal()
  }


  return (
    <dialog className='popup | ' onClick={(e) => {handleClick(e) }} ref={modalRef}>
        <div className='flow-3'>
            <form onSubmit={handleSubmit} action="">
              <div className="name">
                <div className='input-wrapper'> 
                  <label htmlFor="fullName">Full Name or Company </label>
                  <input  onChange={(e) => { handleChange(e) }} type="text" name='fullName' id='fullName' />
                </div>
              </div>
              <div className="email input-wrapper"> <label htmlFor="emailAddress">Email Address</label>
                <input onChange={(e) => { handleChange(e) }} type="email" name="emailAddress" id="emailAddress" required/>
              </div>
              <div className="phone-number input-wrapper"> 
                <label htmlFor="phone-Number">Phone Number</label>
                <input onChange={(e) => { handleChange(e) }} type='tel' name="phoneNumber" id="Phone-number" required/>
              </div>
              <div className="email input-wrapper">
                <label htmlFor="message">Messages</label>
                <textarea onChange={(e) => { handleChange(e) }} name="message" id="message"></textarea>
              </div>
              <button type='submit' className='button' datatype-button="primary">Submit</button>
            </form>
        </div>
    </dialog>
    
  )
}