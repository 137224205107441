import React from 'react'
import Header from '../components/Header'
import Hero from '../components/Hero'
import OurProcess from '../components/OurProcess'
import AboutUs from '../components/AboutUs'
import Cta from '../components/Cta'
import Featured from '../components/Featured'
import Footer from '../components/Footer'

export default function HomePage() {
  return (
    <div>
        <Header />
        <Hero />
        <OurProcess />
        <AboutUs />
        <Cta />
        <Featured />
        <Footer />
    </div>
  )
}
