import React from 'react'
import step1 from '../images/step1.svg'
import step2 from '../images/step2.svg'
import step3 from '../images/step3.svg'

export default function OurProcess() {
  return (
    <section className='our-process container'>
        <h2 className="section-header">Our Process</h2>
        <ul>
            <li>
                <div>
                    <img src={step1} alt="step1" />
                    <h3 className="secondary-header">
                        Target Ideal Customers
                    </h3>
                </div>
                <p className="description">We target ideal customers through market research and segmentation, focusing on high-potential prospects.</p>
            </li>
            <li>
                <div>
                    <img src={step2} alt="step2" />
                    <h3 className="secondary-header">
                        Provide Appealing Ads               
                    </h3>
                </div>
                <p className="description">We create compelling ads for your audience, optimizing delivery on the right platforms for engagement.</p>
            </li>
            <li>
                <div>
                    <img src={step3} alt="step3" />
                    <h3 className="secondary-header">
                        Deliver Quote-Ready Leads
                    </h3>
                </div>
                <p className="description">We qualify leads rigorously, focusing on genuinely interested prospects through pre-screening and personalized follow-ups.</p>
            </li>
        </ul>
    </section>
  )
}
