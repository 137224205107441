import React from 'react'
import featured1 from '../images/featured1.svg'
import featured2 from '../images/featured2.svg'
import featured3 from '../images/featured3.svg'

export default function Featured() {
  return (
    <section className='featured'>
        <div className='featured-wrapper'>
            <ul>
                <li>
                    <img src={featured1} alt="featured1" />
                    <p className="feature">Industry Specialists</p>
                </li>
                <li>
                    <img src={featured2} alt="featured2" />
                    <p className="feature">Guaranteed Results</p>
                </li>
                <li>
                    <img src={featured3} alt="featured3" />
                    <p className="feature">Quality Leads</p>
                </li>
            </ul>
        </div>
    </section>
  )
}

