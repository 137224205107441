import React from 'react'
import logo from '../images/logo.svg'
import whatsapp from '../images/whatsapp.svg'
import facebook from '../images/facebook.svg'
import Instagram from '../images/Instagram.svg'
export default function Footer() {
  return (
    <footer className='footer'>
        <div className="footer-wrapper">
            <img src={logo} alt="logo" className="logo" />
        </div>
        <p className="copyright">
          © 2024 RevampShore, Inc.
        </p>
    </footer>
  )
}
