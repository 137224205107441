import React from 'react'

export default function aboutUs() {
  return (
    <section className='about-us '>
        <div className='container'>
            <h2 className="section-header">About Our Company</h2>
            <p className="body">At RevampShore, we specialize in delivering quote-ready leads to home improvement contractors with minimal effort. Our streamlined process ensures that you receive high-quality prospects ready to convert, allowing you to focus on what you do best—transforming homes. With RevampShore, growing your business has never been easier.</p>
        </div>
    </section>
  )
}
