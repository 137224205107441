import React from 'react'

export default function Cta() {
  return (
    <section className='cta'>
        <h2 className="cta-header | hero-header">Get 10 New <span>Quote-Ready</span> leads</h2>
        <h3 className="secondary-header">within <span>30</span> days, or money back guranteed</h3>
        <button className="button" datatype-button='primary'>Get in Touch</button>
    </section>
  )
}
